import { Disposable } from 'aurelia-binding';
import { autoinject, bindable, BindingEngine } from 'aurelia-framework';
import * as _ from 'lodash';
import { scrollToFirst } from 'utils/scroll-utils';
import { ActionType, ConfirmAppointmentDto, QuestionDto, QuestionResponseDto } from '../../../services/generated-services';
import { WizardSteps } from '../appointment-steps';

@autoinject()
export class AppointmentQuestions {

    @bindable currentStep: WizardSteps;
    @bindable booking: ConfirmAppointmentDto;
    @bindable questions: QuestionDto[];
    @bindable skipDisclaimer: boolean;

    currentResponses: QuestionResponseDto[];
    shouldDisplayBookingInfo: boolean;
    bookingText: string;
    subscriptions: Disposable[] = [];

    constructor(private bindingEngine: BindingEngine) {
    }

    questionId(question: QuestionResponseDto): string {
        return `question-${question.questionOrder}`;
    }

    bind() {
        // Set the current responses
        this.currentResponses =
            _.orderBy(this.booking.questionnaire.responses.filter(r => r.responseOrder !== null), r => r.questionOrder);

        // If there are no current responses, start with the first question
        if (_.isEmpty(this.currentResponses)) {
            var questions = _.orderBy(this.booking.questionnaire.responses, r => r.questionOrder);
            if (this.skipDisclaimer) {
                this.currentResponses = [questions[0], questions[1]];
                this.currentResponses[0].responseOrder = this.currentResponses[0].availableResponses[0].responseOrder;
                scrollToFirst(`#${this.questionId(_.last(this.currentResponses))}`);
            } else {
                this.currentResponses = [questions[0]];
            }
        }

        // Process the last response in case it requires further navigation
        this.handleResponse(_.last(this.currentResponses));
    }

    attached() {
        for (const response of this.booking.questionnaire.responses) {
            this.subscriptions.push(
                this.bindingEngine
                    .expressionObserver(response, 'responseOrder')
                    .subscribe(() => this.handleResponse(response))
            );
        }
    }

    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    private handleResponse(qr: QuestionResponseDto) {
        if (qr.responseOrder) {
            let questionnaire = this.booking.questionnaire;
            let availableResponse = _.find(qr.availableResponses, r => r.responseOrder === qr.responseOrder);

            // Remove all the current responses with a questionOrder greater than the responded question
            _.remove(this.currentResponses, x => x.questionOrder > qr.questionOrder);

            // Also clear the answers to all these responses
            _.each(questionnaire.responses, x => {
                x.responseOrder = x.questionOrder > qr.questionOrder ? null : x.responseOrder;
            });

            // If the response indicates to navigate, also push the current response and navigate ahead
            if (availableResponse.actionType === ActionType.Navigate) {
                // Set the current question
                var nextQuestion = _.find(questionnaire.responses, x => x.questionOrder === availableResponse.navigateQuestionOrder)
                this.currentResponses.push(nextQuestion);
                //if the new question doesn't have responses, show booking info / booking button
                if (nextQuestion.availableResponses.length === 0) {
                    this.shouldDisplayBookingInfo = true;
                }else{
                    //reset to false if the user swaps between next-questions, some with responses and some without
                    this.shouldDisplayBookingInfo = false;
                }
            } else if (availableResponse.actionType === ActionType.Book) {
                // Show the booking info if required
                this.shouldDisplayBookingInfo = true;
                this.bookingText = availableResponse.displayText;
            } else {
                // Make sure we're not showing the booking info otherwise (usually actionType === 'Display')
                this.shouldDisplayBookingInfo = false;
            }

            scrollToFirst(`#${this.questionId(_.last(this.currentResponses))}`);
        }
    }

    goToAppointmentSelect() {
        this.currentStep = WizardSteps.appointmentSelect;
    }
}