import { FrameworkConfiguration, PLATFORM } from "aurelia-framework";

export function configure(aurelia: FrameworkConfiguration) {
  aurelia.globalResources([
    PLATFORM.moduleName('./progress-bar/progress-bar'),
    PLATFORM.moduleName("./appointment-steps/appointment-steps"),
    PLATFORM.moduleName("./theme-text/theme-text"),
    PLATFORM.moduleName('./validation-helpers/control-wrapper/control-wrapper'),
    PLATFORM.moduleName('./validation-helpers/required-info/required-info'),
    PLATFORM.moduleName("./inputs/input-date"),
    PLATFORM.moduleName("./inputs/input-date-time"),
    PLATFORM.moduleName("./resource-date-and-time-picker/resource-date-and-time-picker"),
    PLATFORM.moduleName("./ie-warning/ie-warning"),
    PLATFORM.moduleName("./address-control/address-control"),
    PLATFORM.moduleName("./inputs/optional-attribute"),
    PLATFORM.moduleName("./checkbox-button-list/checkbox-button-list"),
    PLATFORM.moduleName("./radio-button-list/radio-button-list"),
  ]);
}
