import { autoinject, bindable, bindingMode } from 'aurelia-framework';

@autoinject
export class CheckboxButtonList {
  @bindable options;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) value;
  @bindable checkboxListId;

  constructor() {}

  forcedUpdate() {
    this.value = [...this.value]
  }
}