import { ROUTE_SERVER_ERROR } from 'app';
import { autoinject } from 'aurelia-framework';
import { Router, activationStrategy } from 'aurelia-router';
import { ValidationControllerFactory } from 'aurelia-validation';
import { GlobalStateService } from 'services/global-state-service';
import { ValidationBase } from 'utils/validation-base';
import { WizardSteps } from '../../components/appointment-steps/appointment-steps';
import { AppointmentService, ConfirmAppointmentDto, OptionItemDto, PublicAppointmentDto, QuestionResponseDto, QuestionnaireDto, QuestionnaireResponseDto, ReferenceDataService, ScheduleType } from '../../services/generated-services';

@autoinject()
export class AppointmentProcess extends ValidationBase {

    currentStep: WizardSteps = WizardSteps.appointmentQuestions;
    booking: ConfirmAppointmentDto = null;
    // If both a `referrer` and an `externalReference` parameter are present, this will be recorded in the 
    // Appointment `SourceRef` field when a booking is made
    externalReference: string = null;
    skipDisclaimer: boolean;
    states: OptionItemDto[];
    questionnaireIsPublished = true;

    constructor(private readonly appointmentService: AppointmentService,
        private readonly globalStateService: GlobalStateService,
        validationControllerFactory: ValidationControllerFactory,
        private readonly router: Router,
        private readonly referenceDataService: ReferenceDataService) {
        super(validationControllerFactory);
    }

    get mainHeading(): string {
        var service = this.globalStateService.getService();
        return service.scheduleType == ScheduleType.Fixed ? "Book an appointment" : `Book ${service.indefiniteArticleResourceFriendlyName} ${service.resourceFriendlyName}`;
    }

    async activate(params: { referrer?: string, externalReference?: string, step?: string }) {
        const urlParams = new URLSearchParams(window.location.search);
        const questionnairePreviewId = urlParams.get('questionnaire-preview');

        try {
            if (params.step) {
                this.skipDisclaimer = true;
            }
            const extRef = (params.referrer && params.externalReference) ? `${params.referrer}:${params.externalReference}` : null;
            const service = this.globalStateService.getService();

            let questionnaire: QuestionnaireDto = null;


            if (questionnairePreviewId) {
                questionnaire = await this.appointmentService.getQuestionnaireById(service.urlSegment, parseInt(questionnairePreviewId));
                this.questionnaireIsPublished = false;
            } else {
                questionnaire = await this.appointmentService.getQuestionnaire(service.urlSegment);
                this.questionnaireIsPublished = true;
            }

            this.states = await this.referenceDataService.getAustralianStates();
            this.booking = this.initBooking(questionnaire, extRef);
            if (questionnaire == null) {
                this.currentStep = WizardSteps.appointmentSelect;
            }
        } catch (error) {
            this.router.navigateToRoute(ROUTE_SERVER_ERROR);
        }
    }

    private initBooking(questionnaire: QuestionnaireDto, extRef?: string): ConfirmAppointmentDto {
        let emptyResponses = [];

        if (questionnaire != null) {
            emptyResponses = questionnaire.questions.map(q => {
                const response = new QuestionResponseDto();
                response.questionOrder = q.questionOrder;
                response.availableResponses = q.availableResponses;
                response.questionText = q.questionText;
                response.responseOrder = null;
                return response;
            });
        }
        const booking = new ConfirmAppointmentDto();
        booking.appointment = new PublicAppointmentDto();
        booking.appointment.externalReference = extRef;
        booking.questionnaire = new QuestionnaireResponseDto()
        booking.questionnaire.questionnaireName = questionnaire != null ? questionnaire.questionnaireName : "NoActiveQuestionnaire";
        booking.questionnaire.responses = emptyResponses;
        booking.isQuestionnairePublished = this.questionnaireIsPublished;
        return booking;
    }

    determineActivationStrategy() {
        return activationStrategy.replace;
    }
}