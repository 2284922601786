import { autoinject, bindable } from 'aurelia-framework';
import { AttendeeDto, ServiceDto, DynamicFieldDto, DynamicFieldType, OptionItemDto } from './../../../../services/generated-services';
import { GlobalStateService } from 'services/global-state-service';
import { ValidationController, ValidationRules } from 'aurelia-validation';
import { computedFrom } from 'aurelia-binding';
import { camelCase } from 'change-case';

@autoinject()
export class AttendeeDetails {

    @bindable attendee: AttendeeDto;
    @bindable index: number;
    @bindable attendeeHeading: string;
    //used to hook up validations to server response object. might not need anymore?
    @bindable propertyName: string;
    @bindable states: OptionItemDto[];

    service: ServiceDto;

    constructor(private readonly globalStateService: GlobalStateService,
        private readonly validationController: ValidationController) {
        this.service = this.globalStateService.getService();
    }

    bind() {
        if (!this.attendee.data) {
            this.attendee.data = {};
        }
    }

    attached() {
        const rules = [];
        for (let field of this.service.fields) {
            rules.push(
                ...ValidationRules.ensure(camelCase(field.fieldName))
                    //mandatory
                    .required().when(() => field.mandatory)
                    .minItems(1).when(() => field.mandatory && field.fieldType === DynamicFieldType.Checkbox)
                    .withMessage('This field is required.')
                    //text
                    .maxLength(1000).when(x => field.fieldType == DynamicFieldType.Text)
                    //custom regex
                    .matches(new RegExp(field.validationRegex)).when(() => !!field.validationRegex)
                    //email
                    .email().when(x => field.fieldType == DynamicFieldType.Email)
                    //mobile
                    .matches(/^04[0-9]{8}$/).when(x => field.fieldType == DynamicFieldType.MobileNumber)
                    //phone
                    .matches(/^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ] (?=\d{ 3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3}) ?$/).when(x => field.fieldType == DynamicFieldType.PhoneNumber)
                    .rules[0] //first index = first sequence of rules, we want to merge it all into one sequence
            );
        }
        //nest rules into a "sequence" array again
        this.validationController.addObject(this.attendee.data, [rules]);
    }

    detached() {
        this.validationController.removeObject(this.attendee.data);
    }

    @computedFrom("service.fields")
    get orderedFields(): DynamicFieldDto[] {
        return this.service.fields.sort((a, b) => a.order - b.order);
    }
}